import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-icon',
  standalone: false,
  templateUrl: './validation-icon.component.html',
  styleUrl: './validation-icon.component.scss'
})
export class ValidationIconComponent implements OnInit {
  @Input() displayEmpty: boolean = false
  @Input() inputValid: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

}
