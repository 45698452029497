import { Injectable } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class SiteConfigService {
  siteConfig: any
  siteConfigChanged = new BehaviorSubject<any>({ 'status': 'loading' });

  constructor(
    private backendService: BackendService
  ) { }


  fetchSiteConfig() {
    
    this.backendService.fetchSiteConfig().subscribe({
      next: (res) => {
        if(res.theme) {
          switch(res.theme) {
            case 'light':
              break;
            case 'dark':
              document.body.classList.toggle('dark');
              break;
          }
        }
        this.siteConfig = res
        this.siteConfig.status = 'fetched'
        this.siteConfigChanged.next(this.siteConfig)
        return
      },
      error: (err) => {
        console.warn(err)
        this.siteConfigChanged.next({status: 'error'})

        // this.error = err
        return
      },
    });
  }
}
