import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-header-slide',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing-header-slide.component.html',
  styleUrl: './landing-header-slide.component.scss'
})
export class LandingHeaderSlideComponent implements OnInit {
  @Input() sliderDetails: any
  @Input() adjustTop: boolean = false
  constructor() {
  }

  ngOnInit(): void {
      
  }

}
