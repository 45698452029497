import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sail-config-error',
  standalone: false,
  templateUrl: './sail-config-error.component.html',
  styleUrl: './sail-config-error.component.scss'
})
export class SailConfigErrorComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Configuration Error | Event Odyssey')
  }

}
