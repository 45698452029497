<ng-container *ngIf="loading; else configLoaded">
    <ng-container>
        <div class="mt-5 text-center">
            <div class="spinner-eclipse">
                <div class="spin">
                    <div></div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #configLoaded>
    <app-landing-header *ngIf="pageConfig.sliders"
        [sliders]="pageConfig.sliders"></app-landing-header>
    <app-page-content [pageContent]="pageConfig.content"></app-page-content>
</ng-template>