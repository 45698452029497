<ng-container *ngIf="pageContent.length > 0">
    <div *ngFor="let content of pageContent"
        [ngStyle]="{ 'background-color': content.bgColor ? content.bgColor : 'unset' }" class="pt-2 pb-2">
        <div [id]="content.anchor || 'none'" class="container text-center text-md-start mt-2">
            <div [ngSwitch]="content.contentType">
                <ng-template ngSwitchDefault>
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <h5>{{content.name}}</h5>
                                            <p [innerHTML]="content.body"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'asymmetric-dual'">
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-7 col-xl-8 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <h5>{{content.name}}</h5>
                                            <p [innerHTML]="content.body"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [id]="content.sidebarAnchor || 'none-sidebar'"
                            class="col-md-12 col-lg-5 col-xl-4 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <p [innerHTML]="content.sidebarBody"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'asymmetric-gallery-dual'">
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-7 col-xl-8 mx-auto pb-3">
                            <mdb-lightbox class="lightbox">
                                <div class="row">
                                    <div class="col-lg-4 mb-3" *ngFor="let galleryItem of content.photoGalleryItems">
                                                <img mdbLightboxItem [src]="galleryItem.src" [img]="galleryItem.src"
                                                    class="img-fluid rounded x-100" [alt]="galleryItem.alt" />
                                    </div>
                                </div>
                            </mdb-lightbox>
                        </div>
                        <div [id]="content.sidebarAnchor || 'none-sidebar'"
                            class="col-md-12 col-lg-5 col-xl-4 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <p [innerHTML]="content.sidebarBody"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'symmetric-dual'">
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-6 col-xl-6 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <h5>{{content.name}}</h5>
                                            <p [innerHTML]="content.body"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [id]="content.sidebarAnchor || 'none-sidebar'"
                            class="col-md-12 col-lg-6 col-xl-6 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <p [innerHTML]="content.sidebarBody"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'map-info'">
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-6 col-xl-6 mx-auto pb-3">
                            <div class="card">
                                <app-view-map [zoom]="content.mapZoom" [mapStyles]="mapStyles" [lat]="content.lat"
                                    [lng]="content.lng">
                                </app-view-map>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-xl-6 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <h5>{{content.name}}</h5>
                                            <p [innerHTML]="content.body"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'asymmetric-photo'">
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-5 col-xl-4 mx-auto pb-3">
                            <div class="card">
                                <img class="img-fluid rounded" [src]="content.image" style="height: auto; width: 100%">
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-7 col-xl-8 mx-auto pb-3">
                            <div class="card">
                                <div class="card-body" style="padding-bottom: 0px;">
                                    <div>
                                        <div>
                                            <h5>{{content.name}}</h5>
                                            <p [innerHTML]="content.body"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>