<!-- Navbar-->
<nav [style.height]="siteConfig.navigationHeight"
  class="navbar bg-light sticky-top navbar-expand-lg navbar-light navToolbar noselect">
  <div class="container-fluid justify-content-between align-items-center">
    <!-- Left elements -->
    <div class="d-flex align-items-center">
      <!-- Mobile Nav -->
      <ul class="navbar-nav flex-row">
        <li class="nav-item me-3 me-lg-1 active">
          <a (click)="toggle()" class="nav-link pointer">
            <span><i class="fas fa-bars fa-lg"></i></span>
          </a>
        </li>
      </ul>
      <a class="navbar-brand me-2 d-flex align-items-center" routerLink="/home">
        <img [src]="siteConfig.logo" alt="Logo" class="navbar-logo" />
      </a>
    </div>
    <!-- Left elements -->

    <!-- Center elements -->
    <ng-container *ngIf="siteConfig.navigation.length > 0">
      <ul class="navbar-nav flex-row d-none d-md-flex">
        <ng-container *ngFor="let navigationItem of siteConfig.navigation">
          <ng-container *ngIf="navigationItem.childItems; else noChildItems">
            <div mdbDropdown class="btn-group" style="box-shadow: unset" routerLinkActive="inactive">
              <button
                class="dropdown-toggle text-muted me-2"
                style="border: none !important; background: unset;"
                type="button"
                mdbDropdownToggle
                aria-expanded="false">
                <span class="desktopNavItem text-muted">{{navigationItem.name}}</span>
              </button>
              <ul mdbDropdownMenu class="dropdown-menu">
                <li *ngFor="let childItem of navigationItem.childItems"
                  [ngStyle]="{ 'background-color': childItem.bgColor ? childItem.bgColor : 'unset' }"
                  (mouseover)="applyHoverEffect($event, childItem.bgColor)"
                  (mouseout)="removeHoverEffect($event, childItem.bgColor)">
                  <a class="dropdown-item"
                    [routerLink]="navigationItem.route + '/' + childItem.route"
                    [ngStyle]="{ 'color': childItem.bgColor ? getContrastingColor(childItem.bgColor) : 'unset' }">{{childItem.name}}</a>
                </li>
              </ul>
            </div>
          </ng-container>
          <ng-template #noChildItems>
            <li class="nav-item me-3 me-lg-1 active">
              <a class="nav-link" [routerLink]="navigationItem.route" title="Home" routerLinkActive="active">
                <span class="desktopNavItem">{{navigationItem.name}}</span>
              </a>
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </ng-container>

    <!-- Right elements -->
    <ul class="navbar-nav flex-row">
      <li class="nav-item me-3 me-lg-1 active" *ngIf="siteConfig.rightLogo && displayRightLogo">
        <a class="navbar-brand me-2 mb-1 d-flex align-items-center">
          <img [src]="siteConfig.rightLogo" height="40" alt="Right Logo" loading="lazy" />
        </a>
      </li>
    </ul>
  </div>
</nav>
<!-- Navbar -->

<style>
.navbar-logo {
  height: 80%;
  max-height: 60px; /* Ensure it doesn't overflow the navbar */
}
</style>
