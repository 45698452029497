import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact',
  standalone: false,
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.recaptchaV3Service.execute('myAction').subscribe(
      (token) => {
        console.log('Recaptcha v3 token', token);
      },
      (error) => {
        console.log(`Recaptcha v3 error:`, error);
      }
    );
    this.contactForm = new FormGroup({
      details: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      category: new FormControl('General Message', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      captcha: new FormControl(false, Validators.compose([Validators.required])),
    });
  }

  submit() {
    const formData = {
      details: this.contactForm.controls.details.value,
      email: this.contactForm.controls.email.value,
      category: this.contactForm.controls.category.value,
    }
  }

  
  public addTokenLog(message: string, token: string | null) {
    console.log(`${message}: ${token}`);
  }

}
