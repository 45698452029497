<ng-container *ngIf="loading; else configLoaded">
  <ng-container *ngIf="!error; else sailError">
    <app-sail-config-loading></app-sail-config-loading>
  </ng-container>
  <ng-template #sailError>
    <app-sail-config-error></app-sail-config-error>
  </ng-template>
</ng-container>
<ng-template #configLoaded>
  <app-navigation [sidenav]="sidenav"></app-navigation>
  <mdb-sidenav-layout>
    <mdb-sidenav #sidenav="mdbSidenav" mode="over" scrollContainer="#scroll-container" [transitionDuration]="225">
      <div class="container mt-3 mb-2 ms-2">
        <div class="h-100 d-flex flex-row justify-content-center align-items-center">
          <div class="align-self-start flex-fill">
            <img class="ms-1" [src]="siteConfig.logo" style="width: 100%"alt="" loading="lazy" />
          </div>
          <div class="align-self-center">
            <button type="button" class="btn-close me-2" aria-label="Close" (click)="toggle(sidenav)"></button>
          </div>
        </div>
      </div>
      <ul class="sidenav-menu" id="scroll-container" mdbScrollbar>
        <app-sidenav [siteConfig]="siteConfig" [sidenav]="sidenav" (closeSidenav)="sidenav.close()"></app-sidenav>
      </ul>
    </mdb-sidenav>
    <mdb-sidenav-content #sidenavContent>
      <div style="min-height: 600px;" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
      <app-footer [siteConfig]="siteConfig"></app-footer>
    </mdb-sidenav-content>
  </mdb-sidenav-layout>
</ng-template>