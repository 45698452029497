import { Component } from '@angular/core';
import {  endOfDay, MdbCalendarEvent, MdbCalendarModule, startOfDay } from 'mdb-angular-calendar';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [MdbCalendarModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent {
  events: MdbCalendarEvent[] = [
    {
      id: 'event1',
      summary: 'EO Event',
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      color: {
        background: '#cfe0fc',
        foreground: '#0a47a9',
      },
    }
  ];

  test() {
    console.log('aghghg')
  }
}
