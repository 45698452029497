<ng-container *ngIf="inputValid; else displayInputError">
    <ng-container *ngIf="displayEmpty; else displaySuccess">
      <span class="me-3">&nbsp;</span>
    </ng-container>
    <ng-template #displaySuccess>
      <span *ngIf="inputValid" class="text-danger me-2 fas fa-asterisk fa-xs">&nbsp;</span>
    </ng-template>
  </ng-container>
  <ng-template #displayInputError>
    <span class="text-success me-2 fas fa-check fa-xs"></span>
  </ng-template>
  