<div style="min-height: 100vh; display: flex; justify-content: center; background-color: #1E4A90;">
    <div class="row w-100 mt-5">
      <div class="col-md-6 offset-md-3">
        <img src="https://test.eventodyssey.com/assets/ticket-logos/blue.png"
             style="width: 50%; min-width: 125px; max-width: 200px;" class="mb-3">
        <div class="card text-center">
          <div class="card-body">
            <h4>Site Configuration Error</h4>
            <h6>Sorry, the page you are looking for does not exist.</h6>
            <br>
            <h6 class="small">If you're an administrator of this community, please contact 
              <a href="https://eventodyssey.com/support">Event Odyssey Support</a> for assistance.
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  