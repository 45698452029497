<ng-container *ngIf="sliders.length>1; else noSlider">
<div>
    <ngx-glide [arrowLeftTemplate]="arrowLeftTemplate" [arrowRightTemplate]="arrowRightTemplate" [showArrows]="sliders.length>1" [showBullets]="sliders.length>1" [bound]="true" swipeThreshold="50" dragThreshold="100" gap="0" autoplay="8000" class="noselect" style="width: 100% !important; ">
        <ng-container *ngFor="let slider of sliders">
            <app-landing-header-slide [sliderDetails]="slider" [adjustTop]="sliders.length>1"></app-landing-header-slide>
        </ng-container>
    </ngx-glide>
    <ng-template #arrowLeftTemplate>
        <div class="custom-arrow custom-arrow-left">
            <div class="btn btn-rounded btn-dark">
                <i class="fas fa-chevron-left"></i>
            </div>
        </div>
    </ng-template>
    <ng-template #arrowRightTemplate>
        <div class="custom-arrow custom-arrow-right">
            <div class="btn btn-rounded btn-dark">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </ng-template>
</div>
</ng-container>
<ng-template #noSlider>
    <ng-container *ngIf="sliders.length===1">
        <app-landing-header-slide [sliderDetails]="sliders[0]" [adjustTop]="false"></app-landing-header-slide>
    </ng-container>
</ng-template>