import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationService, MdbNotificationRef, MdbNotificationConfig } from 'mdb-angular-ui-kit/notification';
import { ActivatedRoute } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  siteConfig
  @Input() sidenav
  displayRightLogo: boolean = true
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectWidthChanges(window.innerWidth)
  }
  constructor(
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
    private route: ActivatedRoute,
    private siteConfigService: SiteConfigService
    ) {}


  ngOnInit(): void {
    this.detectWidthChanges(window.innerWidth)
    this.siteConfigService.siteConfigChanged.subscribe((siteConfig: any) => {
      this.siteConfig = siteConfig;
    });
  }

  ngOnChanges() {

  }

  
  detectWidthChanges(screenWidth: number) {
    this.displayRightLogo = (screenWidth >= 375)
  }
  
  toggle() {
    this.sidenav.toggle()
  }

  getContrastingColor(hex: string): string {
    if (!hex) {
      return 'unset';
    }

    // Remove the hash if it exists
    hex = hex.replace('#', '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate the luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Lighten or darken the color
    const amount = luminance > 0.5 ? -300 : 300;
    r = Math.min(255, Math.max(0, r + amount));
    g = Math.min(255, Math.max(0, g + amount));
    b = Math.min(255, Math.max(0, b + amount));

    // Convert RGB back to hex
    return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
  }

  getHoverColor(hex: string): string {
    if (!hex) {
      return 'unset';
    }

    // Remove the hash if it exists
    hex = hex.replace('#', '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Darken the color by 10%
    r = Math.max(0, r - 26);
    g = Math.max(0, g - 26);
    b = Math.max(0, b - 26);

    // Convert RGB back to hex
    return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
  }

  componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  applyHoverEffect(event: MouseEvent, bgColor: string | undefined): void {
    if (bgColor) {
      const hoverColor = this.getHoverColor(bgColor);
      (event.target as HTMLElement).style.backgroundColor = hoverColor;
    }
  }

  removeHoverEffect(event: MouseEvent, bgColor: string | undefined): void {
    if (bgColor) {
      (event.target as HTMLElement).style.backgroundColor = bgColor;
    } else {
      (event.target as HTMLElement).style.backgroundColor = 'unset';
    }
  }
}
