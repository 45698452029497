import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() environmentName: string
  @Input() siteConfig
  @Input() sidenav
  version: string = ''
  private httpClient: HttpClient;
  constructor(
    handler: HttpBackend,
    private cdr: ChangeDetectorRef
  ) { 
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.checkVersion()
  }
  checkVersion() {
    this.httpClient.get('./assets/version.txt', {responseType: 'text'}).subscribe((data: any) => {
      this.version = data
  })}

  toggle() {
    this.sidenav.toggle()
    this.cdr.detectChanges()
  }


}
