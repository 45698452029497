import { Component, Input, OnInit } from '@angular/core';
import { NgxGlideModule } from 'ngx-glide';
import { LandingHeaderSlideComponent } from '../landing-header-slide/landing-header-slide.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-landing-header',
  standalone: true,
  imports: [NgxGlideModule, LandingHeaderSlideComponent, CommonModule],
  templateUrl: './landing-header.component.html',
  styleUrl: './landing-header.component.scss'
})
export class LandingHeaderComponent implements OnInit {
  @Input() sliders: any[] = []

  constructor(
    ) {}


  ngOnInit(): void {
  }

}
