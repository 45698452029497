<div class="container" style="width: 100%;">
    <div class="mt-4 mb-4">
        <!-- Grid column -->
        <div class="col-12 mx-auto">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Contact Us</h5>
                </div>
                <div class="card-body pb-3" style="padding-bottom: 0px;">

                    <div style="width: 100%;">
                        <form [formGroup]="contactForm" style="width: 100%">
                            <div class="input-group mt-2 d-flex align-items-center mb-3" style="width: 100%;">
                                <div class="input-container flex-grow-1">
                                    <mdb-form-control>
                                        <input mdbInput [mdbValidate] type="text" formControlName="email" id="email"
                                            class="form-control form-control-lg" [validateSuccess]="false"
                                            placeholder="Email" />
                                        <label mdbLabel class="form-label" for="email">Email</label>
                                    </mdb-form-control>
                                </div>
                                <span class="input-group-text border-0"><app-validation-icon
                                        [inputValid]="contactForm.controls.email.invalid"></app-validation-icon>
                                </span>
                            </div>
                            <div class="input-group mt-2 d-flex align-items-center mb-3" style="width: 100%;">
                                <div class="input-container flex-grow-1">
                                    <mdb-form-control>
                                        <mdb-select id="category" formControlName="category">
                                            <mdb-option value="General Message" selected>General Message</mdb-option>
                                            <mdb-option value="Website Feedback">Website Feedback</mdb-option>
                                            <mdb-option value="other">Other</mdb-option>
                                        </mdb-select>
                                        <label mdbLabel class="form-label">Category</label>
                                    </mdb-form-control>
                                </div>
                                <span class="input-group-text border-0"><app-validation-icon
                                        [inputValid]="contactForm.controls.category.invalid"></app-validation-icon>
                                </span>
                            </div>
                            <div class="input-group mt-2 d-flex align-items-center" style="width: 100%;">
                                <div class="input-container flex-grow-1">
                                    <mdb-form-control>
                                        <textarea mdbInput [mdbValidate] type="text" formControlName="details"
                                            id="details" class="form-control form-control-lg" [validateSuccess]="false"
                                            placeholder="Details"></textarea>
                                        <label mdbLabel class="form-label" for="details">Message</label>
                                    </mdb-form-control>
                                </div>
                                <span class="input-group-text border-0"><app-validation-icon
                                        [inputValid]="contactForm.controls.details.invalid"></app-validation-icon>
                                </span>
                            </div>
                            <div class="input-group mt-2 d-flex align-items-center" style="width: 100%;">
                                <div class="input-container flex-grow-1">
                                    <mdb-form-control>
                                        <re-captcha
                                        formControlName="captcha"
                                        (resolved)="addTokenLog('Reactive form mode resolved', $event)"
                                      >
                                      </re-captcha>
                                    </mdb-form-control>
                                </div>
                                <span class="input-group-text border-0"><app-validation-icon
                                        [inputValid]="contactForm.controls.captcha.invalid"></app-validation-icon>
                                </span>
                            </div>
                            <br>
                            <div class="d-flex justify-content-end">
                                <button (click)="submit()" [disabled]="contactForm.invalid" type="button"
                                    class="btn btn-primary btn-rounded">Submit
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>