<div class="container text-center text-md-start mt-2">
    <!-- Grid row -->
    <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
            <div class="card">
                <div class="card-body text-center" style="padding-bottom: 0px;">
                    <h1>
                        <i class="fas fa-life-ring"></i>
                    </h1>
                    <h5>Page not found</h5>
                    <p>We can't find the page you requested <span *ngIf="pageRoute" class="bg-secondary bg-gradient rounded-pill">&nbsp;&nbsp;&nbsp;{{pageRoute}}&nbsp;&nbsp;&nbsp;</span></p>
                </div>
            </div>
        </div>
    </div>
</div>