import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LandingComponent } from './pages/landing/landing.component'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CurrencyPipe } from '@angular/common'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { DynamicComponent } from './pages/dynamic/dynamic.component'
import { CalendarComponent } from './pages/calendar/calendar.component'
import { ContactComponent } from './pages/contact/contact.component'


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: { animation: 'isLeft' }
  },
  { path: 'home', component: LandingComponent, data: { animation: 'isLeft' } },
  { path: 'not-found', component: NotFoundComponent, data: { animation: 'isLeft' } },
  { path: 'calendar', component: CalendarComponent, data: { animation: 'isLeft' } },
  { path: 'contact-form', component: ContactComponent, data: { animation: 'isLeft' } },
  {
    path: ':rootString',
    component: DynamicComponent,
    data: { animation: 'isLeft' }
  },
  {
    path: ':rootString/:secondaryString',
    component: DynamicComponent,
    data: { animation: 'isLeft' }
  },
  {
    path: ':rootString/:secondaryString/:tertiaryString',
    component: DynamicComponent,
    data: { animation: 'isLeft' }
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'}), BrowserModule, BrowserAnimationsModule],
  providers: [CurrencyPipe],
  exports: [RouterModule]
})
export class AppRoutingModule {}
