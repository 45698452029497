import { Component, ChangeDetectorRef } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { fader } from "./route-animation";
import { Subscription } from "rxjs";
import { Title } from "@angular/platform-browser";
import { SiteConfigService } from "./services/site-config.service";
import { FaviconService } from "./services/favicon.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fader],
})
export class AppComponent {
  title = "eventodyssey-sail";
  loggedIn: string = "not-loaded";
  environmentName: string;
  routeSub: Subscription;
  siteConfig: any;
  loading: boolean = true;
  error: any;

  constructor(
    private titleService: Title,
    private siteConfigService: SiteConfigService,
    private faviconService: FaviconService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.siteConfigService.fetchSiteConfig();
    this.siteConfigService.siteConfigChanged.subscribe((siteConfig: any) => {
      switch (siteConfig.status || "loading") {
        case "loading":
          this.loading = true;
          break;
        case "error":
          this.loading = true;
          this.error = "Config Error";
          break;
        case "fetched":
        default:
          this.loading = false;
          this.siteConfig = siteConfig;
          this.titleService.setTitle(this.siteConfig.name);
          this.faviconService.setFavicon(this.siteConfig.favicon);
          this.cdr.detectChanges(); // Ensure change detection is run after setting siteConfig
          break;
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animation"]
    );
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  toggle($sidenav: any) {
    $sidenav.toggle();
  }
}
