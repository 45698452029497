import { Component } from '@angular/core';

@Component({
  selector: 'app-sail-config-loading',
  standalone: false,
  templateUrl: './sail-config-loading.component.html',
  styleUrl: './sail-config-loading.component.scss'
})
export class SailConfigLoadingComponent {

}
