<div class="container">
<div class="mt-4 mb-4">
    <!-- Grid column -->
    <div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
        <div class="card" >
            <div class="card-body pb-3" style="padding-bottom: 0px;">
                <mdb-calendar
                [events]="events"
                [tooltips]="false"
                [navigation]="true"
                [viewSelect]="false"
                [readonly]="true"
                [addEventButton]="false"
                (eventEdited) = "test()"
                ></mdb-calendar>
            </div>
        </div>
    </div>
</div>
</div>