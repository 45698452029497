<div class="header-slide-container d-flex justify-content-center align-items-center flex-column"
     [ngStyle]="{
       'background-color': sliderDetails.bgColor, 
       'background-image': sliderDetails.bgImage ? 'url(' + sliderDetails.bgImage + ')' : 'none', 
       'background-size': 'cover', 
       'background-position': 'center',
       'color': sliderDetails.textColor ? sliderDetails.textColor : 'inherit'
     }"
     style="height: 300px; width: 100%; text-align: center;">
    <div class="d-flex flex-column justify-content-center align-items-center w-100 px-3 text-wrap"
         [ngClass]="{'adjust-top': adjustTop}" [innerHTML]="sanitizedBody">
    </div>
</div>
