import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { LandingHeaderComponent } from '../landing/landing-header/landing-header.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { PageContentComponent } from 'src/app/shared/page-content/page-content.component';
import { SiteConfigService } from 'src/app/services/site-config.service';

@Component({
  selector: 'app-dynamic',
  standalone: true,
  imports: [LandingHeaderComponent, CommonModule, PageContentComponent],
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent implements OnInit, OnDestroy {
  pageConfig: any;
  siteConfig: any;
  loading: boolean = true;
  private routeSub: Subscription;

  constructor(
    private backendService: BackendService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private siteConfigService: SiteConfigService
  ) { }

  ngOnInit() {
    this.siteConfigService.siteConfigChanged.subscribe((siteConfig: any) => {
      this.siteConfig = siteConfig;
      this.routeSub = this.activatedRoute.url.subscribe(() => {
        this.fetchPageConfig();
      });
    });
  }

  

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  fetchPageConfig() {
    const pathname = window.location.pathname;
    const routeId = pathname.replace(/^\/([^\/]*)/, '/$1').replace(/\//g, '---').replace('---', '/');
    this.backendService.fetchPageContents(`${routeId}`).subscribe({
      next: (res) => {
        this.loading = false;
        this.pageConfig = res;
      },
      error: (err) => {
        console.warn(err);
        // Check if anchor exists in homepage content
        const anchorName = routeId.replace('/', '')
        const homepageAnchorExists = this.siteConfig.homepage.content.some(content => content.anchor === anchorName || content.sidebarAnchor === anchorName);

        if (homepageAnchorExists) {
          this.router.navigate(['/home'], {
            fragment: anchorName
          });
        } else {
          this.router.navigate(['/not-found'], {
            queryParams: {
              redirectUrl: pathname
            }
          });
        }
      }
    });
  }
}
