<ul id="container" mdbScrollbar class="sidenav-menu mb-4 noselect">
  <!-- {{siteConfig | json}} -->
  <mdb-sidenav-item *ngFor="let navigationItem of siteConfig.navigation">
    <a class="sidenav-link" 
       [routerLink]="!navigationItem.childItems ? navigationItem.route : null" 
       (click)="!navigationItem.childItems && toggle()" 
       routerLinkActive="active" 
       mdbWavesEffect>
      <i class="fas fa-fw me-3" [ngClass]="'fa-'+navigationItem.iconName"></i><span>{{navigationItem.name}}</span>
    </a>
    <ul *ngIf="navigationItem.childItems" class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive"
      #rla="routerLinkActive" routerLinkActive>
      <li *ngFor="let childItem of navigationItem.childItems" class="sidenav-item" (click)="toggle()"
        [routerLinkActiveOptions]="{exact: true}" [routerLink]="navigationItem.route + '/' + childItem.route" mdbWavesEffect>
        <a class="sidenav-link">{{childItem.name}}</a>
      </li>
    </ul>
  </mdb-sidenav-item>
</ul>
<div class="mb-3 text-center">
  <small><b>{{siteConfig.name}}</b><br>Version {{version}}</small>
</div>
