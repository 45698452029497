import { Component, OnInit } from '@angular/core';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { CommonModule } from '@angular/common';
import { PageContentComponent } from 'src/app/shared/page-content/page-content.component';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [LandingHeaderComponent, CommonModule, PageContentComponent],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit {
  siteConfig: any
  constructor(
    private siteConfigService: SiteConfigService
    ) {}


  ngOnInit(): void {
    this.siteConfigService.siteConfigChanged.subscribe((siteConfig: any) => {
      this.siteConfig = siteConfig;
    });
  }


}
