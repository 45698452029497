<div style="min-height: 100vh; display: flex; justify-content: center; background-color: #ffffff;">
    <div class="row w-100 mt-5">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="text-white">
              <h5 class="mb-3">
                <app-loading></app-loading>
              </h5>
            </div>
          </div>
    </div>
  </div>
  