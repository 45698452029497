import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-landing-header-slide',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing-header-slide.component.html',
  styleUrl: './landing-header-slide.component.scss'
})
export class LandingHeaderSlideComponent implements OnInit {
  @Input() sliderDetails: any;
  @Input() adjustTop: boolean = false;
  sanitizedBody: SafeHtml | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.sliderDetails && this.sliderDetails.body) {
      // Sanitize the HTML content
      this.sanitizedBody = this.sanitizer.bypassSecurityTrustHtml(this.sliderDetails.body);
    }
  }
}