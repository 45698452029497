<footer class="text-center text-lg-start text-muted"
    style="background-color: var(--footer)">
    <!-- <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"> -->
    <section
        class="d-flex justify-content-center justify-content-lg-between border-bottom">
        <div class="me-5 d-none d-lg-block">
        </div>
        <div>
        </div>
    </section>
    <section class>
        <div class="container text-center text-md-start mt-5">
            <div class="text-center mt-3 mb-2">
                <h6 class="text-uppercase fw-bold mb-4">
                    Useful links
                </h6>
                <span *ngFor="let quickLink of siteConfig.quickLinks; let last = last">
                    <a [href]="quickLink.link" class="text-reset hoverUnderline">{{quickLink.name}}</a>
                    <span *ngIf="!last" style="padding: 0 8px;">|</span>
                </span>
                
                <!-- <div class="col-md-6 col-lg-8 col-xl-6 mx-auto mb-8">
                    <h6 class="text-uppercase fw-bold mb-4">
                        {{siteConfig.name}}
                    </h6>
                    <p *ngIf="siteConfig.description">
                        {{siteConfig.description}}
                    </p>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        Useful links
                    </h6>
                    
                    <p *ngFor="let quickLink of siteConfig.quickLinks">
                        <a [href]="quickLink.link" class="text-reset hoverUnderline">{{quickLink.name}}</a>
                    </p>
                </div> -->
            </div>
        </div>
    </section>
    <div class="text-center p-4" style="background-color: var(--footer-darker)">
        <div>
            © {{currentYear}} <a class="text-reset">{{siteConfig.name}}</a>
        </div>
        <div class="small text-muted">Powered by <a href="https://eventodyssey.com/">Event Odyssey Sail</a></div>
    </div>
</footer>
